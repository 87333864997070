<div class="by-flex">
  <nz-date-picker
    byDisabledMobileKeyboard
    byDisabledDateFixer
    class="hidden-picker"
    nzAllowClear
    [(ngModel)]="dates[1]"
    #rangePicker2
    [nzDisabledDate]="disabledDate"
    (ngModelChange)="onDateRangeChange([dates[0], $event])"
    nzFormat="dd/MM/yyyy"
  ></nz-date-picker>
  <nz-range-picker
    byRangePicker
    byDisabledDateFixer
    #rangePicker1
    [class.hidden-picker]="!rangePicker1?.realOpenState"
    nzAllowClear
    [(ngModel)]="dates"
    #rangePicker
    [nzDisabledDate]="disabledDate"
    (ngModelChange)="onDateRangeChange($event)"
    nzFormat="dd/MM/yyyy"
    [nzSeparator]="'~'"
    [nzRenderExtraFooter]="presetsTemplate"
  ></nz-range-picker>

  <ng-container *ngIf="!rangePicker1?.realOpenState">
    <div *ngIf="buttonTpl; else defaultTpl" (click)="onClick()">
      <ng-container [ngTemplateOutlet]="buttonTpl"> </ng-container>
    </div>

    <ng-template #defaultTpl>
      <div
        (click)="onClick()"
        class="calendar-button ant-btn-{{ buttonType }} pointer"
      >
        <div class="calendar-button__icons-container">
          <i class="far fa-calendar-alt by-mr-5"></i>
          <i class="fas fa-sort-down arrow-calendar"></i>
        </div>
        <ng-container *ngIf="dates.length">
          <div class="calendar-button__dates-container by-ml-5">
            <span class="calendar-button__dates-container__date">{{
              dates[0] | date : 'dd/MM/yyyy'
            }}</span>
            <span style="font-weight: 600"> - </span>
            <span class="calendar-button__dates-container__date">{{
              dates[1] | date : 'dd/MM/yyyy'
            }}</span>
          </div>
          <span *ngIf="showOnClear">
            <i
              class="fal fas by-ml-5 pointer clear-icon"
              nz-tooltip
              [nzTooltipTitle]="'clear_swap_message' | translate | upperfirst"
              nzTooltipPlacement="top"
              (click)="
                $event.stopPropagation();
                $event.preventDefault();
                onDateRangeChange([]);
                dates = []
              "
            ></i>
          </span>
        </ng-container>
      </div>
    </ng-template>
  </ng-container>

  <ng-template #presetsTemplate>
    <div class="preset-ranges">
      <div nz-row nzJustify="center" nzGutter="10">
        <div
          nz-col
          [nzXs]="15"
          [nzSm]="8"
          *ngFor="let preset of presets"
          class="preset-column"
        >
          <button
            class="preset-button"
            (click)="onClickPreset(preset)"
            nz-button
            nzSize="small"
            nzType="primary"
          >
            {{ preset.key | translate : preset.translateParams | upperfirst }}
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</div>
