import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgPipesModule } from '@app/shared/ng-pipes/ng-pipes.module';
import { TranslateModule } from '@ngx-translate/core';

import { UiModule } from '../../ui/ui.module';

import { DateRangeSelectorComponent } from './date-range-selector.component';

@NgModule({
  declarations: [DateRangeSelectorComponent],
  imports: [
    CommonModule,
    NgPipesModule,
    UiModule,
    FormsModule,
    TranslateModule,
  ],
  exports: [DateRangeSelectorComponent],
})
export class DateRangeSelectorModule {}
