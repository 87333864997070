import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  forwardRef,
} from '@angular/core';
import moment from 'moment';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';

import { StatsPreset } from '../../models';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzButtonType } from 'ng-zorro-antd/button';

@Component({
  selector: 'by-date-range-selector',
  templateUrl: './date-range-selector.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangeSelectorComponent),
      multi: true,
    },
  ],
  styleUrls: ['./date-range-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeSelectorComponent implements ControlValueAccessor {
  @Input() dates: Date[] = [];

  @Input() buttonTpl: TemplateRef<any>;

  @Input() showOnClear = true;

  @Input() presets: StatsPreset[];

  @Input() buttonType: NzButtonType = 'primary';

  @Input()
  disabledDate: (date: Date) => boolean = (current) => {
    return moment(current).isAfter(moment());
  };

  @Output() dateFilter = new EventEmitter<Date[]>();

  @ViewChild('rangePicker1')
  nzRangePickerComponent: NzDatePickerComponent;

  onChange: (value: Date[]) => void;

  onTouched: () => void = () => {};

  writeValue(value: Date[]): void {
    this.dates = value || [];
  }

  registerOnChange(onChange: (value: Date[]) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  constructor() {}

  onClick() {
    const input =
      this.nzRangePickerComponent['elementRef'].nativeElement.children[0]
        .children[0];
    input.focus();
    input.click();
  }

  onDateRangeChange(dates: Date[]) {
    this.onChange && this.onChange(dates);
    this.dateFilter.emit(dates);
  }

  onClickPreset(preset: StatsPreset) {
    this.dates = preset.value;
    this.nzRangePickerComponent.close();
    this.onDateRangeChange(this.dates);
  }
}
